<template>
    <zw-sidebar @shown="shown"
                :title="this.payload.id ? $t('common.form.title.editAttributeSet')  + ' (' + this.payload.id + ')' : $t('common.form.title.createAttributeSet')">
        <validation-observer tag="div" ref="observer">
            <b-overlay :show="loading" no-wrap></b-overlay>
            <div v-if="!loading">
                <b-row>
                    <b-col cols="12">
                        <zw-input-group v-model="form.name"
                                        name="name"
                                        :label-prefix="labelPrefix"
                                        validate="required"
                        ></zw-input-group>
                    </b-col>

                    <b-col cols="12">
                        <b-form-group :label="$t(labelPrefix+'attributes')">
                            <multiselect
                                v-model="form.attributes"
                                :options="getAttributes().map(attribute=>parseInt(attribute.id))"
                                :multiple="true"
                                :custom-label="opt => getAttributes().find(attr=>attr.id==opt).name"
                                select-label=""
                            />
                        </b-form-group>
                    </b-col>

                    <b-col cols="12">
                        <b-form-group :label="$t(labelPrefix+'name_order')">
                            <multiselect
                                v-model="form.ordering"
                                :options="getAttributes().filter(attribute=>{
                                    return form.attributes.includes(attribute.id)
                                }).map(attribute=>attribute.name)"
                                :multiple="true"
                                :custom-label="opt"
                                select-label=""
                            >
                                <template slot="tag" slot-scope="{ option, remove }">
                                    <span class="multiselect__tag">
                                        <span>
                                            <font-awesome-icon @click="moveElement(option,-1)"
                                                               icon="arrow-left"
                                                               class="mr-2"
                                            />
                                            {{ option }}</span>
                                        <font-awesome-icon @click="moveElement(option,+1)"
                                                           icon="arrow-right"
                                                           class="ml-2"
                                        />
                                        <i aria-hidden="true" tabindex="1" class="multiselect__tag-icon"
                                           @click="remove(option)"></i>
                                    </span>
                                </template>
                            </multiselect>
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col sm="12" class="text-sm-right">
                        <b-button block @click="onSubmit()" variant="primary">
                            {{ $t('common.button.save') }}
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </validation-observer>
    </zw-sidebar>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'

export default {
    name: 'AttributeSetModal',
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            payload: {},
            callback: null,
            defaultForm: {
                name: '',
                attributes: [],
                ordering: [],
            },
            form: {},
            labelPrefix: 'settings.attribute_set.label.',
        }
    },
    methods: {
        ...mapGetters('Attributes', ['getAttributes', 'getAttributeSet']),
        moveElement(option, direction) {
            let newData = JSON.parse(JSON.stringify(this.form.ordering))
            Array.prototype.move = function (from, to) {
                this.splice(to, 0, this.splice(from, 1)[0]);
            };
            let currentIndex = newData.indexOf(option)

            newData.move(currentIndex, currentIndex + direction)

            this.form.ordering = newData
        },
        shown() {
            const store = this.$store.dispatch('Attributes/fetchAttributeSet', this.payload.id)
            const attributes = this.$store.dispatch('Attributes/fetchAttributes')

            Promise.all([store, attributes])
                .then(() => {
                    this.form = {
                        ...this.defaultForm,
                        ...JSON.parse(JSON.stringify(this.getAttributeSet()))
                    }

                    this.$refs['observer'].reset()
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        onSubmit() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.loading = true

                    this.$store.dispatch('Attributes/saveAttributeSet', this.form)
                        .then((response) => {
                            this.commonAfterSave(response)
                        })
                        .catch(errors => {
                            this.$refs['observer'].setErrors(errors)
                            this.showValidationError()
                        })
                        .finally(() => {
                            this.loading = false;
                        })
                } else {
                    this.showValidationError()
                }
            })
        },
    }
}
</script>